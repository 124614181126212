<template>
  <div>
    <div
      class="text-center"
    >
      <h1>
        Border Countries
      </h1>
      <p>
        This is a force directed graph where a link symbolize a border between two countries.
      </p>
    </div>
    <div>
      <v-slider
        v-model="width"
        thumb-label="always"
        min="0"
        step="10"
        max="10000"
        label="width"
      >
      </v-slider>
      <v-slider
        v-model="ratio"
        thumb-label="always"
        min="0.5"
        step="0.01"
        max="4"
        label="ratio"
      >
      </v-slider>
      <v-slider
        v-model="strength"
        thumb-label="always"
        min="-1000"
        max="0"
        label="strength"
      >
      </v-slider>
      <v-slider
        v-model="textsize"
        thumb-label="always"
        min="0"
        max="250"
        label="Font size"
      >
      </v-slider>
      <div
        v-if="countries_with_borders_number"
      >
        {{
          countries_with_borders_number()
        }} countries with land boundaries / {{
          countries_number()
        }} countries
      </div>
    </div>
    <div
      v-if="mapLoading"
    >
      <v-progress-linear
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-linear>
    </div>
    <div
      
      class="svgContainer"
    >
      <svg
        id="map"
        :viewBox="`0 0 ${width} ${height}`"
        preserveAspectRatio="xMinYMin meet"
        cursor="grab"
      >
        <g
          id="nodesAndLinks"
        >
          <g
            class="links"
          >
            <g
              v-for="link in links"
              :key="link.id"
            >
              <line
                :x1="link.source.x"
                :y1="link.source.y"
                :x2="link.target.x"
                :y2="link.target.y"
                stroke="grey" 
                stroke-width="1"
                opacity="0.5"
                cursor="pointer"
              ></line>
            </g>
          </g>
          <g
            class="nodes"
          >
            <g 
              
              v-for="country_with_borders in countries_with_borders"
              :key="country_with_borders.id"
            >
              <circle
                class="node"
                :cx="country_with_borders.x"
                :cy="country_with_borders.y"
                :r="5"
                fill="grey"
                cursor="pointer"
                opacity="0.5"
                stroke="black"
                stroke-width="1"
              ></circle>
              <text
                :x="country_with_borders.x"
                :y="country_with_borders.y"
                :font-size="textsize"
                fille="none"
                stroke="white"
                stroke-width="2"
                stroke-opacity="0.3"
                cursor="pointer"
              >
                {{
                  country_with_borders.name
                }}
              </text>
            </g>
          </g>
          
        </g>
      </svg>
      
      <pre
        v-if="false && factbook && countries_with_borders"
      >
        {{
          mathchingCountry("South Korea")
        }}
      </pre>
      <pre
        v-if="false && factbook && countries_with_borders && links"
      >
        {{
          links
        }}
      </pre>
      <!-- <pre
        v-if="factbook"
      >
        {{
          countries_with_borders
        }}
      </pre> -->
    </div>
    <!-- <div
      v-if="factbook && countries"
    >
      <pre>
        {{
          factbook.data.countries[countries[3]]
        }}
      </pre>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
// import _ from 'lodash';
import Fuse from 'fuse.js'
import * as d3 from "d3";

export default {
  name:"Editor",
  data() {
    return {
      nodes: null,
      g: null,
      svg: null,
      ratio: 2,
      textsize:20,
      strength:-100,
      width:2450,
      factbook: null,
      simulation: null,
      mapLoading: true
    }
  },
  watch: {
    
    'links': function(){
      var i = 0
      while (i < 10) {
        this.createSimulation()
        this.simulation.tick(100)
        i++
      }
      this.mapLoading = false
    }, 
    'width': function (){
      this.createSimulation()
    },
    'ratio': function (){
      this.createSimulation()
    },
    'strength': function(){
      this.createSimulation()
    },
  },
  mounted() {
    this.svg = d3.select('#map')
    this.g = d3.select('#nodesAndLinks')
    const zoom = d3.zoom()
      .scaleExtent([0.1,10])
      .on("zoom", this.zoomed)
    this.svg.call(zoom)

    axios
      .get(
        'https://raw.githubusercontent.com/iancoleman/cia_world_factbook_api/master/data/factbook.json'
      )
      .then(
        (
          response
        ) => {
          this.factbook = response
        }
      )
  },
  methods: {
    started(
      event
    ) {
      console.log(event)
      // var circle = d3.select(this).classed("dragging", true);

      // event.on("drag", dragged).on("end", ended);

      // function dragged(event, d) {
      //   circle.raise().attr("cx", d.x = event.x).attr("cy", d.y = event.y);
      // }

      // function ended() {
      //   circle.classed("dragging", false);
      // }
    },
    zoomed(
      event
    ) {
      const {transform} = event;
      this.g.attr("transform", transform)
      this.g.attr("stroke-width", 1 / transform.k)
    },
    createSimulation(
    ){
      this.simulation = d3
        .forceSimulation(
          this.countries_with_borders
        )
        .force("charge", d3.forceManyBody().strength((d)=> {
          if (
            d.border_countries.length <= 2
          ) {
            return (
              d.border_countries.length * this.strength * 10
            )
          }
          else {
            return (
              d.border_countries.length * this.strength
            )
          }
        }))
        .force("link", d3.forceLink(this.links).id(
          (d)=>d.name)
        )
        .force('x', d3.forceX())
        .force('y', d3.forceY())
        .force("collide", d3.forceCollide((d)=>d.border_countries.length*10))
        .force("center", d3.forceCenter(
          this.centerX,
          this.centerY
        )
      );
      this.nodes = d3.selectAll(".node").call(
        d3.drag()
          .on(
            "start", 
            (d)=> {
              console.log(d)
            }
          )
          .on(
            "drag", 
            (d)=> {
              console.log(d.x, d.y)
            }
          )
          .on(
            "end", 
            (d)=> {
              console.log(d)
            }
          )
      )
    },
    mathchingCountry(
      name
    ) {
      var mathchingCountry;
      var options = {
        shouldSort: true,
        includeScore: true,
        keys: [
          {
            name: "name",
            weight: 2
          },
          {
            name: "country_name.abbreviation",
            weight: 4
          },
          {
            name: "country_name.conventional_short_form",
            weight: 6
          }
        ]
      }
      var fuse = new Fuse(
        this.countries_with_borders,
        options 
      )
      mathchingCountry = fuse.search(
        name.split("(")[0]
      )[0]
      return(
        mathchingCountry ? mathchingCountry.item.name : `error${name}`
      )
    },
    listKeys(
      object
    ) {
      return (
        Object.keys(
          object
        )
      )
    },
    isntEmpty(
      object
    ) {
      return (
        Object.keys(object).length === 0 && 
        object.constructor === Object
      )
    },
    countries_number(){
      var countries_number = null
      if (
        this.countries 
      ) {
        countries_number = this.countries.length 
      }
      return (
        countries_number
      )
    },
    border_countries_average_number(){
      var border_countries_average_number = null
      if (
        this.countries_with_borders
      ) {
        border_countries_average_number = this.countries_with_borders.map(
          (
            country_with_borders
          ) => {
            return (
              country_with_borders.border_countries.length
            )
          }
        )
        .reduce(
          (
            a,
            b
          ) => {
            return (
              a + b / 
              this.countries_with_borders_number
            )
          }
        )
      }
      return (
        border_countries_average_number
      )
    },
    countries_with_borders_number(){
      var countries_with_borders_number = null
      if (
        this.countries_with_borders
      ) {
        countries_with_borders_number = this.countries_with_borders.length
      }
      return (
        countries_with_borders_number
      )
    },
  },
  computed: {
    // countries_with_borders_names(){
    //   var countries_with_borders_names = []
    //   if (
    //     this.countries_with_borders
    //   ) {
    //     countries_with_borders_names = this.countries_with_borders.map(
    //       (
    //         country_with_borders
    //       ) => {
    //         return (
    //           country_with_borders.name
    //         )
    //       }
    //     )
    //   }
    //   return countries_with_borders_names
    // },
    height(){
      return(
        this.width / this.ratio
      )
    },
    centerX(){
      return (
        this.width/2
      )
    },
    centerY(){
      return (
        this.height/2
      )
    },
    yellow(){
      return (
        d3.interpolateYlGn(0)
      )
    },
    links(){
      var links = []
      if (
        this.countries_with_borders
      ) {
        this.countries_with_borders.forEach(
          (
            country_with_borders
          ) => {
            country_with_borders.border_countries.forEach(
              (
                bordering_country
              ) => {
                  if (
                    bordering_country.slice(0,2) === "US"
                  ) {
                    bordering_country = "United States"
                  }
                  if (
                    bordering_country === "French Guiana"
                  ) {
                    bordering_country = "France"
                  }
                  links.push(
                    {
                      source: country_with_borders.name,
                      target: this.mathchingCountry(bordering_country)
                    }
                  )
              }
            )
          }
        )
      }
      return (
        links
      )
    },
    countries() {
      var countries = []
      if (
        this.factbook
      ) {
        countries = Object.keys(
          this.factbook.data["countries"]
        )
      }
      return (
        countries
      )
    },
    countries_with_borders() {
      if (
        this.factbook && this.countries
      ) {
        var borders = this.countries
        .slice()
        .filter(
          (
            country
          ) => {
            return (
              Object.prototype.hasOwnProperty.call(
                this.factbook.data["countries"][country].data.geography,
                'land_boundaries'
              ) &&
              Object.prototype.hasOwnProperty.call(
                this.factbook.data["countries"][country].data.geography.land_boundaries,
                'border_countries'
              )
            )
          }
        )
        .map(
          (
            country
          ) => {
            return {
              name: this.factbook.data["countries"][country].data.name,
              country_name: this.factbook.data["countries"][country].data.government.country_name,
              border_countries: this.factbook.data["countries"][country].data.geography.land_boundaries.border_countries.map(
                (
                  border_country
                ) => {
                  return (
                    border_country["country"]
                  )
                }
              )
            }
          }
        )
        return (
          borders
        )
      }
      else {
        return (
          null
        )
      }
    },
    abbreviations() {
      var abbreviations = Object.keys(
            this.factbook.data["countries"]
          ).slice()
          .filter(
            (
              country
            ) => {
              return (
                Object.prototype.hasOwnProperty.call(
                  this.factbook.data["countries"][country].data.geography, 
                  'land_boundaries'
                ) &&
                Object.prototype.hasOwnProperty.call(
                  this.factbook.data["countries"][country].data.geography.land_boundaries, 
                  'border_countries'
                ) &&
                Object.prototype.hasOwnProperty.call(
                  this.factbook.data["countries"][country].data.government.country_name, 
                  'abbreviation'
                )
              )
            }
          )
          .map(
            (
              country
            ) => {
              return (
                this.factbook.data["countries"][country].data.government.country_name.abbreviation.split(" ")[0]
              )
            }
          )
      return (
        abbreviations
      )
    }
  }
}
</script>

<style scoped>

.svgContainer{
  border: 2vw solid black;
}

</style>
